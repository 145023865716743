.circularProgressWrapper{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {}