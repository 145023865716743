:root {
  --heroFont: 20vh;
  --color-dark: #471f65;
  --color-dark-scrollbar: #121314;
  --color-light: #efb89e;
}

body {
  min-width: 100vw;
  min-height: 100vh;
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #121314;
  font-family: Roboto, Helvetica, Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1600px) {
  body {
    font-size: 1.1667rem !important;
  }
}
@media (max-width: 1599px) {
  body {
    font-size: 1rem !important;
  }
}
@media (max-width: 899px) {
  body {
    font-size: 1.1667rem !important;
  }
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  /* width: 8px; */
  /* background: #e5e4e2;
  border-left: 0 solid #e5e4e2;
  border-right: 0 solid #e5e4e2; */
  background: var(--color-dark-scrollbar);
  border-left: 0 solid var(--color-dark-scrollbar);
  border-right: 0 solid var(--color-dark-scrollbar);
}
::-webkit-scrollbar-thumb {
  /* background: #d43030; */
  background: var(--color-dark);
  width: 0;
  height: 25%;
  transition: 0.5s ease;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
